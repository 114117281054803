<template>
  <div
    class="overlay"
    :class="{active: active}"
    @click="$emit('closeOverlay')"
  >
  </div>
</template>

<script>
export default {
  name: 'MyOverlay',
  props: {
    active: Boolean
  }
};
</script>

<style scoped lang="scss">
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 500;
    transition: all 300ms linear;
    opacity: 0;
    visibility: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: all 300ms linear !important;
    }
  }

</style>