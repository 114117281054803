<template>
  <div class="balanceUp">
    <div class="container fluid">
      <h1>{{ $localize('profile')['balanceUp']['title'] }}</h1>

      <div class="row">
        <div class="col-xl-5 col-ml-5 col-lg-6">
          <div class="bunus_card">
            <div class="left">
              <p>{{ $localize('balancePopup')['personalBalance'] }}:</p>

              <h3>{{ $t('currency', { price: $numberFormat(balance.deposit) }) }}</h3>
            </div>

            <div class="right">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.01 10.4568C10.01 9.35777 10.901 8.46677 12 8.46677C13.099 8.46677 13.99 9.35777 13.99 10.4568C13.99 11.3478 13.441 12.0088 12.597 12.3558C12.209 12.5148 12 12.8558 12 12.8558M11.9982 15.5332L12 15.5302M19.998 11.999C19.998 16.4161 16.4171 19.997 12 19.997C7.58278 19.997 4.00195 16.4161 4.00195 11.999C4.00195 7.5818 7.58278 4.00098 12 4.00098C16.4171 4.00098 19.998 7.5818 19.998 11.999Z"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-ml-5 col-lg-6">
          <div class="bunus_card_white">
            <div class="left">
              <div class="icon">
                <img src="@/assets/profile_assets/numbers.svg" alt="" />
              </div>
            </div>

            <div class="right">
              <h3>{{ $localize('profile')['balanceUp']['numberPersonalAccount'] }}</h3>

              <p>{{ userInfo.phone }}</p>
            </div>
          </div>
        </div>
      </div>

      <h2>{{ $localize('profile')['balanceUp']['replenishmentMethods'] }}</h2>

      <div class="row">
        <div class="col-xl-2 col-lg-2 col-sm-4 col-6">
          <a href="javascript:" class="card" @click="active = true">
            <div class="top">
              <span>{{ $localize('profile')['balanceUp']['cards'] }}</span>
            </div>

            <div class="bottom">
              <div class="card_con">
                <img src="@/assets/profile_assets/card_bonus.svg" alt="" />
              </div>
            </div>
          </a>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-4 col-6">
          <div class="card">
            <div class="top">
              <span>Click</span>
            </div>

            <div class="bottom">
              <div class="card_con">
                <img src="@/assets/profile_assets/click.svg" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-4 col-6">
          <div class="card">
            <div class="top">
              <span>Payme</span>
            </div>

            <div class="bottom">
              <div class="card_con">
                <img src="@/assets/profile_assets/payme.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <my-overlay @closeOverlay="closeOverlay" :active="active"/>

      <transition>
        <balance-top-up
          v-if="active"
          :cards="cardUser.slice(1, cardUser.length)"
          @closeOverlay="closeOverlay"
          :active="active"
        />
      </transition>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BalanceTopUp from '@/components/profile/BalanceTopUp.vue';
import BuyerService from '@/services/buyer.service';
import MyOverlay from '@/components/UI/MyOverlay';
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);

export default {
  data: () => ({
    active: false,
  }),
  components: {
    MyOverlay,
    BalanceTopUp,
  },
  methods: {
    async closeOverlay() {
      this.active = false
      await BuyerService.balance();
    },
  },
  computed: {
    ...mapGetters('buyers', ['balance', 'userInfo', 'cardUser' ]),
  },
  created() {
    BuyerService.cards();
  },
};
</script>

<style lang="scss" scoped>
  .balanceUp {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: 100%;

    h2 {
      margin-top: 8px;
      margin-bottom: 16px;
    }

    h1 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 24px;
    }

    .bunus_card {
      background: url("../../assets/profile_assets/bonusCardBg.svg") no-repeat center center / cover;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: $white;
        margin-bottom: 8px;
      }

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.326451px;
        color: $white;
      }
    }

    .bunus_card_white {
      display: flex;
      align-items: center;
      padding: 20px 16px;
      background: $white;
      border-radius: 8px;

      .icon {
        padding: 6px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
      }

      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $black;
        margin-bottom: 4px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: $black;
      }
    }

    .card {
      padding: 12px;
      padding-bottom: 0px;
      border-radius: 8px;
      background: white;
      display: block;
      color: $black;

      .bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .card_con {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .col-6 {
    width: 50%;
  }

  @media (max-width: 767px) {
    .balanceUp {
      padding: 27px 0px;
    }
  }

  @media (max-width: 992px) {
    .balanceUp {
      padding: 27px 0px;

      .bunus_card_white {
        margin-bottom: 24px;
      }

      .card {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 575px) {
    .balanceUp {
      .container {
        padding-left: 6px;
        padding-right: 6px;

        .row {
          margin-right: -6px;
          margin-left: -6px;
        }

        [class*="col-"] {
          padding-left: 6px;
          padding-right: 6px;
        }
      }

      h1 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
      }

      .bunus_card_white {
        margin-bottom: 24px;
      }

      .bunus_card {
        margin-bottom: 12px;
      }
    }
  }

  @media (min-width: 576px) {
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.3333333333% !important;
    }
  }

  @media (min-width: 992px) {
    .container .col-xl-2 {
      width: calc(100% / 12 * 2) !important;
    }
  }

  @media (min-width: 1400px) {
    .container .col-xl-2 {
      width: calc(100% / 12 * 2) !important;
    }
  }
</style>
