<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }" class="card_balance" :class="{ active: active }">
    <div class="top">
      <div class="icon">
        <img src="@/assets/profile_assets/credit-card-hand.svg" alt="" />
      </div>
      <a href="javascript:" class="close" @click="$emit('closeOverlay')">
        <img src="@/assets/profile_assets/closeSidebar.svg" alt="" />
      </a>
    </div>

    <div class="card-body" v-if="step === 1">
      <h3>{{ $localize('profile')['balanceUp']['title'] }}</h3>
    </div>

    <swiper v-if="step === 1" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(card, i) in cards" :key="i">
        <div class="card" :style="`background:url(${card.style}) no-repeat center center / cover;`" :class="card.theme">
          <div class="card_top">
            <div class="card_left">
              <h3>{{ $localize('balancePopup')['paymartCart'] }}</h3>

              <p>{{ $t('currency', { price: $numberFormat(card.balance) }) }}</p>
            </div>

            <div class="card_right">
              <div class="frame" :style="`background-image:url(${card.logo});`"></div>
            </div>
          </div>

          <div class="card_bottom">
            <div class="card_left">{{ card.pan }}</div>

            <div class="card_right">{{ card.exp }}</div>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div v-if="step === 1">
      <div class="form_input">
        <label for="inp2" class="form-label">{{ $localize('profile')['history']['sum'] }}</label>
        <ValidationProvider name="sum" v-slot="{ errors }" rules="required|min_value:1000">
          <input type="tel" v-model="contract" v-mask="priceMask" :placeholder="$localize('sign-up')['sumPlaceholder']"
            id="inp2" class="form-control" />
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <main-button @click="handleSubmit(loadingHandler)" :is-loading="loading" :disabled="disabled || invalid">
        {{ $localize('button')['pay'] }}
      </main-button>
    </div>

    <div class="success" v-else>
      <img src="@/assets/profile_assets/success.svg" alt="" />
      <h3>{{ $localize('profile')['balanceUp']['successPaid'] }}</h3>
      <main-button @click="$emit('closeOverlay')" :disabled="disabled">
        {{ $localize('button')['close'] }}
      </main-button>
    </div>
  </ValidationObserver>
</template>

<script>
import 'swiper/swiper-bundle.min.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { ValidationObserver } from 'vee-validate'
import MainButton from '@/components/buttons/MainButton'

export default {
  props: {
    active: {
      type: Boolean,
    },
    cards: {
      type: Array,
    },
  },
  components: { MainButton, Swiper, SwiperSlide, ValidationObserver },
  computed: {
    priceMask() {
      let mask = '';

      switch (this.contract.length) {
        case 4:
          mask = '# ###';
          break;
        case 6:
          mask = '## ###';
          break;
        case 7:
          mask = '### ###';
          break;
        case 8:
          mask = '# ### ###';
          break;
        case 10:
          mask = '## ### ###';
          break;
        case 11:
          mask = '### ### ###';
          break;

        default:
          mask = '';
          break;
      }

      return mask;
    },
  },
  data: () => ({
    loading: false,
    contract: '',
    disabled: false,
    step: 1,
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 32,
      grabCursor: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 1,
          spaceBetween: 32,
        },
        1300: {
          slidesPerView: 1,
          spaceBetween: 32,
        },
        1600: {
          slidesPerView: 1,
          spaceBetween: 32,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    },
  }),
  methods: {
    loadingHandler() {
      this.loading = true;
      this.disabled = true;
      document.querySelectorAll('.swiper-slide').forEach(async (n, i) => {

        if (n.classList.contains('swiper-slide-active')) {

          if (this.step === 1) {
            try {
              const { data: res } = await this.$axios.post('buyer/deposit/add', {
                card_id: this.cards[i].id,
                sum: Number(this.contract.replace(/[^0-9]/g, '')),
              });
              this.loading = false;
              this.disabled = false;
              if (res.status === 'success') {
                this.step = 2;
              } else {
                this.error = true;
                res.response.message.forEach(({ text }) => this.$toastError(this.$localize(text)));
              }

            } catch (e) {
              this.$toastError(e.message);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card_balance {
  padding: 40px 32px 32px;
  max-width: 400px;
  background: $white;
  width: 400px;
  min-height: calc(100vh - 96px);
  position: fixed;
  top: 96px;
  right: 0;
  z-index: 1000;
  transform: translateX(0px);

  &.v-enter-active,
  &.v-leave-active {
    transition: transform 500ms ease;
  }

  &.v-enter,
  &.v-leave-to {
    transform: translateX(500px);
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .icon {
      padding: 6px;
      background: rgba(102, 16, 245, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      margin-bottom: 24px;
    }
  }

  .form_input {
    margin-bottom: 15px;
    width: 100%;

    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
    }

    small {
      color: red;
    }
  }
}

.swiper-container {
  padding-bottom: 35px;
  margin-top: 16px;
  margin-bottom: 14px;
}

.swiper-slide {
  .card {
    padding: 16px;
    border-radius: 8px;

    &.whiteThema {
      color: white;
    }

    .card_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 88px;

      .card_left {
        h3 {
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .card_right {
        .frame {
          width: 72px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: top right;
          background-size: contain;
        }
      }
    }

    .card_bottom {
      display: flex;
      justify-content: space-between;

      .card_left {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }

      .card_right {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

.success {
  text-align: center;
  margin-top: 50px;

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    margin-top: 28px;
    margin-bottom: 40px;
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 575px) {
  .card_balance {
    padding: 24px 16px 16px;
    max-width: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    top: auto;
    transform: translateX(0px);
    transform: translateY(2000px);
    overflow: auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    min-height: auto;

    &::after {
      content: "";
      display: block;
      position: absolute;
      background: $black;
      opacity: 0.3;
      border-radius: 4px;
      left: 50%;
      top: 10px;
      transform: translateX(-50%);
      width: 56px;
      height: 4px;
    }

    &.active {
      transform: translateY(0px);
    }

    .top {
      display: none;
    }

    .card-body {
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.38px;
        margin-bottom: 8px;
      }
    }

    ul.toggle {
      margin-bottom: 12px;

      li {
        a {
          padding: 11px 12px;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.154px;
        }
      }
    }

    .info-card {
      padding: 12px 10px;

      .icon {
        img {
          width: 20px;
          height: 20px;
        }
      }

      .info_title {
        h3 {
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.01em;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
        }
      }
    }
  }

  .swiper-container {
    margin-top: 12px;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .swiper-slide {
    .card {
      .card_top {
        margin-bottom: 60px;

        .card_left {
          h3 {
            font-size: 14px;
            line-height: 16px;
          }

          p {
            font-weight: bold;
            font-size: 19px;
            line-height: 23px;
          }
        }
      }

      .card_bottom {
        .card_left {
          font-size: 15px;
          line-height: 28px;
        }

        .card_right {
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
  }
}

.error {
  margin-top: 5px;
  font-size: 14px;
  color: red;
}
</style>
